class TempLicensesOrderService {
  getData(page, data) {
    return axios.post('general/temp_license_order?page=' + page, data)
  }

  edit_temp_licenses(id) {
    return axios.get('general/temp_license_order/edit/' + id)
  }
  create_temp_licenses_order() {
    return axios.get('general/temp_license_order/create')
  }

  get_form_details(data){
    return axios.get('general/temp_license_order_form/get-questions-form/7')
  }

  add_temp_licenses(data) {
    return axios.post('general/temp_license_order/store', data)
  }
  update_temp_licenses(id, data) {
    return axios.post('general/temp_license_order/update/' + id, data)
  }

  destroy_temp_licenses(id) {
    return axios.delete('general/temp_license_order/destroy/' + id)
  }

  store_kroky(data) {
    return axios.post('general/temp_license_order_kroky_documents/store', data)
  }

  delete_kroky(id) {
    return axios.post('general/temp_license_order_kroky_documents/delete/' + id)
  }
}
export default new TempLicensesOrderService();